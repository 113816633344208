<template>
  <div class="info-popup popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】上游客户信息' : (todo == 'edit' ? '【修改】上游客户信息' : '【查看】上游客户信息')"
      :close-on-click-modal="false"
      width="75%"
      top="50px"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" size="small">
        <div class="boxSize">
          <p class="title">
            <span class="separate">|</span>基本信息
          </p>
          <div class="infoBox infoBox1">
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="客户代码" prop="customerCode">
                  <el-input v-model="dataForm.customerCode" :disabled="isViews" placeholder="客户代码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户全称" prop="fullName">
                  <el-input v-model="dataForm.fullName" :disabled="isViews" placeholder="客户全称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户简称" prop="simpleName">
                  <el-input v-model="dataForm.simpleName" :disabled="isViews" placeholder="客户简称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="所属行业" prop="industry">
                  <el-input v-model="dataForm.industry" :disabled="isViews" placeholder="行业"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="主营业务" prop="business">
                  <el-input v-model="dataForm.business" :disabled="isViews" placeholder="主营业务"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户等级" prop="grade">
                  <el-select class="item-choose"
                             v-model="dataForm.grade"
                             :disabled="isViews"
                             filterable
                             clearable
                             placeholder="请选择"
                             style="width: 100%"
                  >
                    <el-option
                      v-for="item in  dictTypeMap.customer_grade"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="客户阶段" prop="stage">
                  <el-select class="item-choose" v-model="dataForm.stage" :disabled="isViews" filterable clearable placeholder="请选择" style="width: 100%">
                    <el-option label="待跟进" :value="1"></el-option>
                    <el-option label="跟进中" :value="2"></el-option>
                    <el-option label="成交" :value="3"></el-option>
                    <el-option label="流失" :value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人" prop="contactName">
                  <el-input v-model="dataForm.contactName" :disabled="isViews" placeholder="联系人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input v-model="dataForm.contactPhone" :disabled="isViews" placeholder="可输入手机号码或固定电话"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="地址" prop="address">
                  <el-input v-model="dataForm.address" :disabled="isViews" placeholder="地址"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="企业信用代码" prop="creditCode">
                  <el-input v-model="dataForm.creditCode" :disabled="isViews" placeholder="企业信用代码/个人身份证号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公司附件" prop="enclosureInfoList">
                  <al-upload :fileData="dataForm.enclosureInfoList"
                             :fileUrl="uploadUrl"
                             :format="'file'"
                             :type="'offer'"
                             :limit="12"
                             :businessType="2000"
                             @getFileData="getFileData"></al-upload>
                  <span style="color: red; font-size: 13px;">（营业执照/合同资料/开票资料）</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="跟进人" prop="followId">
                  <el-select class="item-choose"
                             ref="optionRef"
                             v-model="dataForm.followId"
                             :disabled="isViews"
                             filterable
                             clearable
                             placeholder="请选择"
                             style="width: 100%"
                  >
                    <el-option
                      v-for="item in  userJobMap.SALE_DEP"
                      :key="item.id"
                      :label="item.realName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="备注" prop="remark">
                  <el-input type="textarea" :rows="1" v-model="dataForm.remark" :disabled="isViews"
                            placeholder="备注"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="boxSize" v-if="isFindAll || todo == 'add'">
          <p class="title">
            <span class="separate">|</span>对接人信息
          </p>
          <div class="infoBox infoBox1">
<!--            <el-row :gutter="50">-->
<!--              <el-col :span="12">-->
<!--                <el-form-item label="业务部" prop="saleDepIdList">-->
<!--                  <el-select class="item-choose"-->
<!--                             v-model="dataForm.saleDepIdList"-->
<!--                             :disabled="isViews"-->
<!--                             filterable-->
<!--                             multiple-->
<!--                             clearable-->
<!--                             placeholder="请选择"-->
<!--                             style="width: 100%"-->
<!--                  >-->
<!--                    <el-option-->
<!--                      v-for="item in  userJobMap.SALE_DEP"-->
<!--                      :key="item.id"-->
<!--                      :label="item.realName"-->
<!--                      :value="item.id"-->
<!--                    ></el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="12">-->
<!--                <el-form-item label="客服部" prop="careDepIdList">-->
<!--                  <el-select class="item-choose"-->
<!--                             v-model="dataForm.careDepIdList"-->
<!--                             :disabled="isViews"-->
<!--                             filterable-->
<!--                             multiple-->
<!--                             clearable-->
<!--                             placeholder="请选择"-->
<!--                             style="width: 100%"-->
<!--                  >-->
<!--                    <el-option-->
<!--                      v-for="item in userJobMap.CARE_DEP"-->
<!--                      :key="item.id"-->
<!--                      :label="item.realName"-->
<!--                      :value="item.id"-->
<!--                    ></el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
            <el-row :gutter="50">
              <el-col :span="7">
                <el-form-item label="业务员" prop="saleIdList">
                  <el-select class="item-choose"
                             v-model="dataForm.saleIdList"
                             :disabled="isViews"
                             filterable
                             multiple
                             clearable
                             placeholder="请选择"
                             style="width: 100%"
                             multiple-limit="1"
                  >
                    <el-option
                      v-for="item in  userJobMap.SALE"
                      :key="item.id"
                      :label="item.realName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="业务员提成(%)" prop="salesmanFee">
                  <el-input v-model="dataForm.salesmanFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="业务经理" prop="saleAdminIdList">
                  <el-select class="item-choose"
                             v-model="dataForm.saleAdminIdList"
                             :disabled="isViews"
                             filterable
                             multiple
                             clearable
                             placeholder="请选择"
                             style="width: 100%"
                             multiple-limit="1"
                  >
                    <el-option
                      v-for="item in userJobMap.SALE_ADMIN"
                      :key="item.id"
                      :label="item.realName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="业务经理提成(%)" prop="salesmanManagerFee">
                  <el-input v-model="dataForm.salesmanManagerFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="7">
                <el-form-item label="客服员" prop="careIdList">
                  <el-select class="item-choose"
                             v-model="dataForm.careIdList"
                             :disabled="isViews"
                             filterable
                             multiple
                             clearable
                             placeholder="请选择"
                             style="width: 100%"
                             multiple-limit="1"
                  >
                    <el-option
                      v-for="item in  userJobMap.CARE"
                      :key="item.id"
                      :label="item.realName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="客服员提成(%)" prop="serviceFee">
                  <el-input v-model="dataForm.serviceFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="客服经理" prop="careAdminIdList">
                  <el-select class="item-choose"
                             v-model="dataForm.careAdminIdList"
                             :disabled="isViews"
                             filterable
                             multiple
                             clearable
                             placeholder="请选择"
                             style="width: 100%"
                             multiple-limit="1"
                  >
                    <el-option
                      v-for="item in  userJobMap.CARE_ADMIN"
                      :key="item.id"
                      :label="item.realName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="客服经理提成(%)" prop="managerFee">
                  <el-input v-model="dataForm.managerFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="boxSize" v-if="isFindAll || todo == 'add'">
          <p class="title">
            <span class="separate">|</span>个性化费用信息
          </p>
          <div class="infoBox infoBox1">
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="装/卸加点价" prop="addFee">
                  <el-input v-model="dataForm.addFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算周期(天)" prop="settleCycle">
                  <el-input v-model="dataForm.settleCycle" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="费用(%)" prop="maintainFee">
                  <el-input v-model="dataForm.maintainFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="8">
                <el-form-item label="税金" prop="taxesFee">
                  <el-input v-model="dataForm.taxesFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
<!--              <el-col :span="8">-->
<!--                <el-form-item label="业务员提成(%)" prop="salesmanFee">-->
<!--                  <el-input v-model="dataForm.salesmanFee" :disabled="isViews" placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="8">-->
<!--                <el-form-item label="业务经理提成(%)" prop="salesmanManagerFee">-->
<!--                  <el-input v-model="dataForm.salesmanManagerFee" :disabled="isViews" placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="8">
                <el-form-item label="自营司机提成(%)" prop="royaltyFee">
                  <el-input v-model="dataForm.royaltyFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="共赢运费应付(%)" prop="allWinFee">
                  <el-input v-model="dataForm.allWinFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
<!--            <el-row :gutter="50">-->
<!--              <el-col :span="8">-->
<!--                <el-form-item label="客服提成(%)" prop="serviceFee">-->
<!--                  <el-input v-model="dataForm.serviceFee" :disabled="isViews" placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="8">-->
<!--                <el-form-item label="经理提成(%)" prop="managerFee">-->
<!--                  <el-input v-model="dataForm.managerFee" :disabled="isViews" placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="8">-->
<!--                <el-form-item label="自营司机提成(%)" prop="royaltyFee">-->
<!--                  <el-input v-model="dataForm.royaltyFee" :disabled="isViews" placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
            <el-row :gutter="50">
<!--              <el-col :span="8">-->
<!--                <el-form-item label="共赢运费应付(%)" prop="allWinFee">-->
<!--                  <el-input v-model="dataForm.allWinFee" :disabled="isViews" placeholder="请输入"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="8">
                <el-form-item label="个体挂靠提点(%)" prop="individualFee">
                  <el-input v-model="dataForm.individualFee" :disabled="isViews" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
      <div>
        <p class="title">
          <span class="separate">|</span>联系人信息
        </p>
        <div class="contacts">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="150px" size="small">
            <el-row v-for="(item, index) in dataForm.contactList" :key="index" :gutter="50">
              <el-col :span="6">
                <el-form-item label="客户联系人" prop="contactName">
                  <el-input v-model="item.contactName" :disabled="isViews" placeholder="联系人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-input v-model="item.position" :disabled="isViews" placeholder="职位"></el-input>
              </el-col>
              <el-col :span="4">
                <el-input v-model="item.contactPhone" :disabled="isViews" placeholder="可输入手机号码或固定电话"></el-input>
              </el-col>
              <el-col :span="4">
                <el-input v-model="item.email" :disabled="isViews" placeholder="邮箱账号"></el-input>
              </el-col>
              <el-col :span="3">
                <el-select class="item-choose" v-model="item.receiveEmail" filterable clearable placeholder="接受邮件">
                  <el-option label="接收邮件" :value="1"></el-option>
                  <el-option label="不接收邮件" :value="2"></el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-input v-model="item.remark" :disabled="isViews" placeholder="备注"></el-input>
              </el-col>
              <el-col :span="1" style="text-align: center;">
                  <span class="deleteBtn" v-if="todo != 'view' && dataForm.contactList.length != 1"
                        @click="deleteContact(index)">
                      <i class="el-icon-delete"></i>
                    </span>
                <span class="add" @click="addContact(index)"
                      v-if="todo != 'view' && index == dataForm.contactList.length-1">
                      <i class="el-icon-circle-plus-outline"></i>
                    </span>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <el-form :model="templateDataForm" ref="templateDataForm" label-width="100px" size="small">
        <div style="margin-top:8px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>费用模板</label>
          <el-button style="margin-left: 20px;" round type="primary" size="mini" @click="addTemplateRow()"
                     v-if="todo != 'view'">添加
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="templateDataForm.templateList" border size="mini"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="50">
            </el-table-column>
            <el-table-column prop="templateCode" label="模板编号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="templateName" align="center" label="模板名称">
              <template slot-scope="scope">
                <el-select class="item-choose" v-model="templateDataForm.templateList[scope.$index].templateId"
                           :disabled="isViews"
                           size="mini" style="width: 100%" @change="((val)=>{showTemplateCode(val, scope.$index)})"
                           filterable clearable placeholder="请选择">
                  <el-option v-for="item in quoteTemplate"
                             :key="item.id"
                             :label="item.templateName"
                             :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="endTime" label="有效期" align="center" width="300">
              <template slot-scope="scope">
                <div>
                  <el-date-picker :disabled="isViews"
                                  v-model="templateDataForm.templateList[scope.$index].dateRange"
                                  type="daterange" range-separator="-" size="mini" align="center" style="width: 100%"
                                  value-format="yyyy-MM-dd"
                                  start-placeholder="生效日期"
                                  end-placeholder="失效日期">
                  </el-date-picker>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80" v-if="todo !='view'">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.$index)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <div>
        <div class="infoBox infoBox2">
          <el-tabs type="border-card">
            <el-tab-pane label="营销邮件记录">
              <el-table :data="dataForm.sendOfferFormList" border size="mini"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%">
                <el-table-column type="index" label="序号" align="center" width="50">
                </el-table-column>
                <el-table-column prop="templateName" label="报价单名称" width="180">
                </el-table-column>
                <el-table-column prop="contactName" label="客户联系人" width="200">
                </el-table-column>
                <el-table-column prop="mailSubject" label="邮件主题" width="180">
                </el-table-column>
                <el-table-column prop="mailContent" label="邮件内容">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="200">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="营销跟踪记录">
              <el-table :data="dataForm.marketTrackList" border size="mini"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%">
                <el-table-column type="index" label="序号" align="center" width="50">
                </el-table-column>
                <el-table-column prop="marketTrackType" label="反馈方式" width="100">
                  <template slot-scope="scope">
                    <span v-if="scope.row.marketTrackType == 1"> 短信 </span>
                    <span v-if="scope.row.marketTrackType == 2"> 微信 </span>
                    <span v-if="scope.row.marketTrackType == 3"> 电话 </span>
                    <span v-if="scope.row.marketTrackType == 4"> 其他 </span>
                  </template>
                </el-table-column>
                <el-table-column prop="content" label="反馈内容">
                </el-table-column>
                <el-table-column label="附件" prop="" width="230">
                  <template slot-scope="scope">
                    <div v-for="(file, index) in scope.row.enclosureInfoList" :key="index">
                     <span @click="handleDownload(file)" style="color:blue;cursor:pointer">{{file.name}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="fillName" label="填报人" width="150">
                </el-table-column>
                <el-table-column prop="fillTime" label="填报时间" width="200">
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit(todo)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { trim } from 'lodash'

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        customerCode: null,
        creditCode: null,
        fullName: null,
        followId: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        salesmanName: null,
        remark: null,
        addFee: null,
        email: null,
        settleCycle: null,
        maintainFee: null,
        taxesFee: null,
        salesmanFee: null,
        allWinFee: null,
        individualFee: null,
        salesmanManagerFee: null,
        serviceFee: null,
        managerFee: null,
        royaltyFee: null,
        enclosureInfoList: [],
        saleIdList: [],
        saleAdminIdList: [],
        careIdList: [],
        careAdminIdList: [],
        saleDepIdList: [],
        careDepIdList: [],
        contactList: [{
          contactName: null,
          contactPhone: null,
          receiveEmail: 2,
          email: null,
          realType: 3, // 加入企业
          status: 1
        }],
        industry: null,
        business: null,
        grade: null,
        stage: null,
        marketTrackList: [], // 营销跟踪记录
        sendOfferFormList: [] // 邮件跟踪记录
      },
      templateDataForm: {
        templateList: []
      },
      userJobMap: new Map(),
      quoteTemplate: [],
      uploadUrl: '',
      accessToken: '',
      licenseBusinessType: 1000,
      licenseFileList: [],
      dataRule: {
        fullName: [
          { required: true, message: '客户全称不能为空', trigger: 'blur' }
        ],
        customerCode: [
          { required: true, message: '客户代码不能为空', trigger: 'blur' }
        ],
        simpleName: [
          { required: true, message: '客户简称不能为空', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, message: '企业信用代码不能为空', trigger: 'blur' }
        ],
        // contactName: [
        //   { required: true, message: '联系人不能为空', trigger: 'blur' }
        // ],
        // contactPhone: [
        //   { required: true, message: '联系电话不能为空', trigger: 'blur' },
        //   { validator: validateMobile, trigger: 'blur' }
        // ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ]
      },
      todo: '', // 当前弹框类型， add:新增 ； edit：编辑 ； view：查看
      isFindAll: true
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isViews () {
      return this.todo === 'view'
    }
  },
  methods: {
    // 获取用户信息
    getUserList () {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/customer/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    clearSelectUser (that) {
      that.dataForm.saleIdList = []
      that.dataForm.saleAdminIdList = []
      that.dataForm.careIdList = []
      that.dataForm.careAdminIdList = []
      that.dataForm.saleDepIdList = []
      that.dataForm.careDepIdList = []
      that.dataForm.marketTrackList = []
      that.dataForm.sendOfferFormList = []
      that.dataForm.enclosureInfoList = []
      that.dataForm.customerCode = null
      that.dataForm.creditCode = null
      that.dataForm.fullName = null
      that.dataForm.simpleName = null
      that.dataForm.contactName = null
      that.dataForm.contactPhone = null
      that.dataForm.address = null
      that.dataForm.salesmanName = null
      that.dataForm.remark = null
      that.dataForm.addFee = null
      that.dataForm.email = null
      that.dataForm.settleCycle = null
      that.dataForm.maintainFee = null
      that.dataForm.taxesFee = null
      that.dataForm.salesmanFee = null
      that.dataForm.allWinFee = null
      that.dataForm.individualFee = null
      that.dataForm.salesmanManagerFee = null
      that.dataForm.serviceFee = null
      that.dataForm.managerFee = null
      that.dataForm.royaltyFee = null
      that.dataForm.contactList = [{
        contactName: null,
        contactPhone: null,
        receiveEmail: 2,
        email: null,
        realType: 3, // 加入企业
        status: 1
      }]
      that.dataForm.industry = null
      that.dataForm.business = null
      that.dataForm.grade = null
      that.dataForm.stage = null
      that.dataForm.followId = null
    },
    // 获取附件信息
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    // 添加联系人
    addContact (index) {
      this.dataForm.contactList.push({
        contactName: null,
        contactPhone: null,
        receiveEmail: 2,
        email: null,
        status: 1
      })
    },
    // 删除
    deleteContact (index) {
      if (this.dataForm.contactList.length === 1) {
        this.$message.error('至少要有一条联系人信息。')
        return false
      }
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.dataForm.contactList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    showTemplateCode (templateId, index) {
      if (!templateId) {
        this.templateDataForm.templateList[index].templateCode = null
        return
      }
      for (let i = 0; i < this.quoteTemplate.length; i++) {
        if (this.quoteTemplate[i].id === templateId) {
          this.templateDataForm.templateList[index].templateCode = this.quoteTemplate[i].templateCode
          break
        }
      }
    },
    deleteRow (index) {
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.templateDataForm.templateList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    },
    addTemplateRow () {
      this.templateDataForm.templateList.push({
        dateRange: null
      })
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = infoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.licenseFileList.push({ id: infoList[i].id, name: infoList[i].name, url: infoList[i].url })
        }
      }
    },
    // 初始化费用模板数据
    initTemplate (data) {
      let templateList = data.templateList
      if (templateList) {
        for (let i = 0; i < templateList.length; i++) {
          let dateRange = []
          dateRange[0] = templateList[i].beginTime
          dateRange[1] = templateList[i].endTime
          this.templateDataForm.templateList.push({
            templateId: templateList[i].templateId,
            dateRange: dateRange
          })
          this.showTemplateCode(templateList[i].templateId, i)
        }
      }
    },
    init (todo, id) {
      let that = this
      // that.dataForm = {}
      this.uploadUrl = this.$http.adornUrl('/upload/file')
      this.accessToken = this.$cookie.get('TmsToken')
      this.licenseFileList = []
      that.templateDataForm.templateList = []
      that.dataForm.id = id
      this.todo = todo
      if (!this.newAuth('customer:manager:findAll')) {
        this.isFindAll = false
      }
      this.getUserList()
      this.clearSelectUser(that)
      this.$http({
        url: this.$http.adornUrl('/priceTemplate/findAll'),
        method: 'get',
        params: this.$http.adornParams({
          'templateType': 10
        })
      }).then(({ data }) => {
        this.quoteTemplate = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/customer/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data) {
              // that.dataForm = data
              that.dataForm.customerCode = data.customerCode
              that.dataForm.creditCode = data.creditCode
              that.dataForm.fullName = data.fullName
              that.dataForm.simpleName = data.simpleName
              that.dataForm.address = data.address
              that.dataForm.contactName = data.contactName
              that.dataForm.contactPhone = data.contactPhone
              that.dataForm.address = data.address
              that.dataForm.salesmanName = data.salesmanName
              that.dataForm.remark = data.remark
              that.dataForm.addFee = data.addFee
              that.dataForm.email = data.email
              that.dataForm.settleCycle = data.settleCycle
              that.dataForm.maintainFee = data.maintainFee
              that.dataForm.taxesFee = data.taxesFee
              that.dataForm.salesmanFee = data.salesmanFee
              that.dataForm.saleIdList = data.saleIdList
              that.dataForm.saleAdminIdList = data.saleAdminIdList
              that.dataForm.careIdList = data.careIdList
              that.dataForm.careAdminIdList = data.careAdminIdList
              that.dataForm.saleDepIdList = data.saleDepIdList
              that.dataForm.careDepIdList = data.careDepIdList
              that.dataForm.salesmanManagerFee = data.salesmanManagerFee
              that.dataForm.serviceFee = data.serviceFee
              that.dataForm.managerFee = data.managerFee
              that.dataForm.royaltyFee = data.royaltyFee
              that.dataForm.allWinFee = data.allWinFee
              that.dataForm.individualFee = data.individualFee
              that.dataForm.contactList = data.contactList
              that.dataForm.industry = data.industry
              that.dataForm.business = data.business
              that.dataForm.stage = data.stage
              that.dataForm.grade = data.grade
              that.dataForm.marketTrackList = data.marketTrackList
              that.dataForm.sendOfferFormList = data.sendOfferFormList
              that.dataForm.followId = data.followId
              this.initFileList(data)
              this.initTemplate(data)
            }
          })
        }
      })
    },
    // 封装费用模板表单
    pickTemplateForm () {
      let templateList = this.templateDataForm.templateList
      if (templateList.length === 0) {
        return true
      }
      for (let i = 0; i < templateList.length; i++) {
        let element = templateList[i]
        if (element.dateRange && element.dateRange.length === 2) {
          element.beginTime = element.dateRange[0]
          element.endTime = element.dateRange[1]
        } else {
          delete element.beginTime
          delete element.endTime
        }
        if (!element.templateId) {
          this.$message.error('费用模板中，第【' + (i + 1) + '】行未选择模板。')
          return false
        }
        if (!element.beginTime || !element.endTime) {
          this.$message.error('费用模板中，第【' + (i + 1) + '】行有效期错误。')
          return false
        }
      }
      return true
    },
    // 表单校验
    checkValid () {
      if (!trim(this.dataForm.creditCode)) {
        this.$message.error('请完善企业信用代码')
        return false
      }
      if (!this.dataForm.simpleName) {
        this.$message.error('请完善客户简称')
        return false
      }
      if (!this.dataForm.customerCode) {
        this.$message.error('请完善客户代码')
        return false
      }
      if (!this.dataForm.fullName) {
        this.$message.error('请完善客户全称')
        return false
      }
      if (!this.dataForm.address) {
        this.$message.error('请完善客户地址信息')
        return false
      }
      for (let i = 0; i < this.dataForm.contactList.length; i++) {
        if (!this.dataForm.contactList[i].contactName) {
          this.$message.error('请完善客户联系人客户名称')
          return false
        }
      }
      return true
    },
    // 表单提交
    dataFormSubmit (todo) {
      if (!this.checkValid()) {
        return false
      } else if (todo === 'view') {
        this.visible = false
      } else {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (!this.pickTemplateForm()) {
              return
            }
            let methodName = !this.dataForm.id ? 'add' : 'update'
            let method = !this.dataForm.id ? 'put' : 'post'
            this.$http({
              url: this.$http.adornUrl(`/customer/` + methodName),
              method: method,
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'customerCode': this.dataForm.customerCode,
                'creditCode': this.dataForm.creditCode,
                'fullName': this.dataForm.fullName,
                'simpleName': this.dataForm.simpleName,
                'address': this.dataForm.address,
                'contactList': this.dataForm.contactList,
                'salesmanName': this.dataForm.salesmanName,
                'contactName': this.dataForm.contactName,
                'contactPhone': this.dataForm.contactPhone,
                'addFee': this.dataForm.addFee,
                'email': this.dataForm.email,
                'settleCycle': this.dataForm.settleCycle,
                'maintainFee': this.dataForm.maintainFee,
                'taxesFee': this.dataForm.taxesFee,
                'salesmanFee': this.dataForm.salesmanFee,
                'salesmanManagerFee': this.dataForm.salesmanManagerFee,
                'serviceFee': this.dataForm.serviceFee,
                'managerFee': this.dataForm.managerFee,
                'royaltyFee': this.dataForm.royaltyFee,
                'saleIdList': this.dataForm.saleIdList,
                'saleAdminIdList': this.dataForm.saleAdminIdList,
                'careIdList': this.dataForm.careIdList,
                'careAdminIdList': this.dataForm.careAdminIdList,
                'saleDepIdList': this.dataForm.saleDepIdList,
                'careDepIdList': this.dataForm.careDepIdList,
                'allWinFee': this.dataForm.allWinFee,
                'individualFee': this.dataForm.individualFee,
                'templateList': this.templateDataForm.templateList,
                'enclosureInfoList': this.dataForm.enclosureInfoList,
                'remark': this.dataForm.remark,
                'industry': this.dataForm.industry,
                'business': this.dataForm.business,
                'grade': this.dataForm.grade,
                'stage': this.dataForm.stage,
                'followId': this.$refs.optionRef.selected.value,
                'followName': this.$refs.optionRef.selected.label
              })
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        })
      }
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      if (file.type === this.enclosureDto.excel13Type ||
        file.type === this.enclosureDto.excel07Type ||
        file.type === this.enclosureDto.pdfType) {
        file.url = resData.url
      }
      this.dataForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    // 删除文件信息
    removeFileList (file) {
      let fileId = file.id
      let list = this.dataForm.enclosureInfoList
      // 从集合删除
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === fileId) {
          list.splice(i, 1)
          break
        }
      }
    },
    handleDownload (file) {
      this.$http({
        url: this.$http.adornUrl(`/upload/download/stream/${file.id}`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        var link = document.createElement('a')
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = file.name
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    limitExceed (data, files) {
      if (files.length === 2) {
        this.$message.error('文件上传限制为2个文件')
      }
    },
    beforeUpload (file) {
      if (file.type === this.$store.state.common.enclosureDto.jpegType ||
        file.type === this.$store.state.common.enclosureDto.pngType ||
        file.type === this.$store.state.common.enclosureDto.excel13Type ||
        file.type === this.$store.state.common.enclosureDto.excel07Type ||
        file.type === this.$store.state.common.enclosureDto.pdfType) {
        return true
      }
      this.$message.error('仅支持上传jpg、png、Excel、pdf格式文件')
      return false
    },
    beforeRemoveFileList () {
      if (this.todo === 'view') {
        return false
      }
    }
  }
}
</script>

<style lang="less">
.info-popup {
  .infoBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px
  }

  .infoBox1 {
    max-height: 320px;
    overflow: auto;
    padding-right: 25px;
    padding-top: 20px;

    .el-form-item {
      margin-bottom: 4px;
      // .el-upload {
      //   float: left
      // }
      // ul.el-upload-list{
      //     float: left;
      //     .el-upload-list__item-name {
      //       width: 100%;
      //     }
      //     // label {
      //     //   right: -42%;
      //     // }
      // }
      // .el-upload__tip {
      //     display: none;
      // }
      // .el-upload-list__item {
      //   width: 100% !important;
      //   margin-top: 4px;
      //   margin-left: 4px;
      //   // float: left;
      //   height: auto !important;
      // }
      // // .el-upload-list__item:hover .el-icon-close {
      // //     right: -42%;
      // // }
      // .el-icon-close-tip {
      //   display: none
      // }
    }
  }

  .contacts {
    display: block;
    // width: 100%;
    // margin-left: 14px;
    // margin: 5px 10px;
    margin: 10px 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;

    .el-row {
      width: 100%;

      .el-col {
        padding: 0 !important;
        height: 32px;
        line-height: 32px;
      }

      .add, .deleteBtn {
        // position: absolute;
        font-size: 20px;
        color: #409EFF;
        // top: 5px;
        cursor: pointer;
      }

      .el-form-item {
        margin-bottom: 0
      }

      // .add {
      //   right: -15px;
      // }
      .deleteBtn {
        // right: 5px;
        color: red
      }
    }
  }

  .el-dialog {
    margin-top: 40px !important;

    .el-input__inner {
      height: 26px !important;
    }

    .el-dialog__header {
      padding: 10px 20px 10px;
    }
  }

  .el-dialog__body {
    padding: 0;
    max-height: 800px;
    overflow: auto;

    .title {
      padding-left: 21px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .el-dialog__footer {
    padding: 10px 20px 10px;

    .el-button {
      height: 32px;
      line-height: 0;
    }
  }

  .infoBox2 {
    padding: 25px;

    td {
      padding: 2px 0
    }
  }
}
</style>
